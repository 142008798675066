@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Noto Sans", "Apple SD Gothic Neo", Sans-serif;
  --swiper-theme-color: #ffffff;
}

.swiper-pagination-bullet-active {
  width: 1.5rem !important;
  border-radius: 9999px !important;
  background: #ffffff !important;
}

.swiper-pagination-bullet {
  background: #b1b1b1 !important;
}
